.pyui_dropdown {
  width: 30rem;
  min-width: 20rem;
  max-width: 100%;
  position: relative;
  user-select: none;

  &-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-label {
      font-weight: 500;

      &.zerostate {
        color: #aaa;
      }
    }
  }
  &-inner {
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
    font-size: 0.875rem;
    background-color: white;
    padding: 0.5rem 1rem;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 0px 20px;

    &::after {
      display: block;
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      border-right: 1px solid var(--color-text-default);
      border-bottom: 1px solid var(--color-text-default);
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      top: 12px;
      transition: all 0.3s;
    }

    &.expanded::after {
      top: 18px;
      transform: rotate(-135deg);
      transition: transform 0.3s ease-in;
    }
  }

  &-list {
    position: absolute;
    top: auto; // 105%;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease-in;
    z-index: 3;

    &-item {
      font-size: 0.875rem;
      cursor: pointer;
      color: var(--color-text-default);
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #eee;
      transition: all 0.1s ease-in;
    }
    &-item:not(.disabled):hover {
      background-color: rgba(156, 39, 176, 0.2);
    }
  }

  & .disabled {
    cursor: default;
    opacity: 0.7;
    border-radius: 0;
    margin: 0;
  }
}
