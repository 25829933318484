html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", "Roboto", sans-serif;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  font-weight: 400;
  line-height: normal;
  color: var(--color-text-default);
}

a {
  color: #061725; //TODO
  text-decoration: none;
  font-weight: 550;
}

// SCROLLBAR

::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
  border: 1px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}
