:root {
  --color-neutral: #333333;
  --color-primary: #ff9800;
  --color-secondary: #1c84c3; //#009bcf;
  --color-accent: #9c27b0; //#f58634;
  --color-text-default: var(--color-neutral);
  --color-bg-light: #fff;
  --color-white: #fff;
  --color-black: #000000;
  --body-bg: #f8f9fa;

  --color-primary-op-80: hsl(36deg 100% 50% / 80%);
  --color-secondary-op-80: hsl(195.07deg 100% 40.59% / 80%);

  --color-neutral-op-90: hsl(0deg 0% 20% / 90%);
  --color-neutral-op-80: hsl(0deg 0% 20% / 80%);
  --color-neutral-op-75: hsl(0deg 0% 20% / 75%);
}
