@use "../../assets/styles/_Scss/placeholder";

.utility-spinner {
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-progressboard {
  .pyui_main-container-section-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .page-title {
    @extend %h2;
    text-align: center;
    // margin-bottom: 1rem;
  }
}

// TODO placeholder
.badge-list {
  grid-gap: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  &-item {
    background-color: #fff;
    border: 1px dashed #c7c7c7;
    border-radius: 0.5rem;
    color: var(--color-text-default);
    padding: 1rem;
    text-align: center;

    &_title {
      color: #5f5f5f;
      font-size: 0.875rem;
    }

    &_data {
      color: var(--color-text-primary);
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
}

.group_progress {
  @extend %ProgressBlock;
}

.group_progress-userexgrp,
.group_progress-userattend,
.group_progress-concepts {
  @extend %ProgressBlockItem;
}

// session tabs
.pyui_tabbar_streched {
  display: flex;
  align-items: center;
  background: hsl(0deg 0% 93.33% / 61%);
  padding: 0 1rem;
  border-bottom: 1px solid #d9d9d9;

  .tab-item {
    flex-grow: 1;
    text-align: center;
    border-bottom: 2px solid transparent;
    padding: 0.75rem;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &.selected {
      color: var(--color-secondary);
      border-bottom: 2px solid var(--color-secondary);
      transition: all 0.2s ease-in-out;
    }
  }
}

// session tabs
.pyui_tabbar_small {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  gap: 1rem;
  border-bottom: 1px solid #eee;

  .tab-item {
    text-align: center;
    border-bottom: 2px solid transparent;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.selected {
      color: var(--color-secondary);
      border-bottom: 2px solid var(--color-secondary);
      transition: all 0.2s ease-in-out;
    }
  }
}

.pyui_user_summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: var(--block-border);
  border-radius: 0.5rem;

  .title {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    margin-top: 1rem;
  }

  &-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;

    .details-title {
      font-weight: 600;
    }
  }

  .select-interval {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem 0;
  }

  &-table {
    padding: 0 0.5rem 0.5rem 0.5rem;

    &-inner {
      height: 22.5rem;
      overflow-y: auto;
      overflow-x: auto;

      & {
        @extend %tableScrollbar;
      }
    }
  }
}

// select interval
.pyui_session {
  width: 15rem;
  min-width: 10rem;
  max-width: 100%;
  position: relative;
  user-select: none;

  &-inner {
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
    font-size: 0.875rem;
    background-color: white;
    padding: 0.5rem 1rem;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 0px 20px;

    &::after {
      display: block;
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      border-right: 2px solid var(--color-text-default);
      border-bottom: 2px solid var(--color-text-default);
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      top: 11px;
      transition: all 0.3s;
    }

    &.expanded::after {
      top: 14px;
      transform: rotate(-135deg);
      transition: transform 0.3s ease-in;
    }
  }

  &-list {
    position: absolute;
    top: 105%;
    width: 15rem;
    background-color: white;
    transition: all 0.3s ease-in;
    z-index: 3;

    &-item {
      font-size: 0.875rem;
      cursor: pointer;
      color: var(--color-text-default);
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #eee;
      transition: all 0.1s ease-in;

      &-date {
        font-size: 0.875rem;
        cursor: pointer;
        color: var(--color-text-default);
        padding: 0.5rem 1rem;

        &::after {
          display: block;
          content: "";
          width: 0.5rem;
          height: 0.5rem;
          border-right: 2px solid var(--color-text-default);
          border-bottom: 2px solid var(--color-text-default);
          transform: rotate(45deg);
          position: absolute;
          right: 15px;
          top: 12.5rem;
          transition: all 0.3s;
        }

        &.expand::after {
          top: 12.85rem;
          transform: rotate(-135deg);
          transition: transform 0.3s ease-in;
        }
      }
    }

    &-item:not(.disabled):hover {
      background-color: rgb(228, 243, 255);
    }
  }

  & .disabled {
    cursor: default;
    opacity: 0.7;
    border-radius: 0;
    margin: 0;
  }
}