:root {
  --color-input-br: rgba(145, 142, 142, 0.37);
  --color-input-br-hover: var(--color-secondary-op-80);
  --color-input-focus: #8ed6fc;
}

.pyui-msg {
  padding: 0.25rem;
  font-size: 0.876rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  // margin-block-end: 0.5rem;

  &.success {
    background-color: hsl(154deg 70% 88%);
    color: hsl(155deg 100% 25%);
    border-color: hsl(154deg 71% 83%);
  }

  &.error {
    background-color: hsl(350deg 78% 91% / 56%);
    color: hsl(349deg 61% 45%);
    border-color: hsl(349deg 79% 87%);
  }

  &.information {
    background-color: hsl(198deg 100% 92%);
    color: hsl(198deg 73% 46%);
    border-color: hsl(198deg 97% 87%);
  }

  &.warning {
    background-color: hsl(51, 100%, 92%);
    color: var(--color-primary);
    border-color: var(--color-primary);
  }

  &-text {
    // padding: 0.25rem;
    font-weight: 300;
    display: block;
  }
}

// TODO check if used
.error-msg {
  background-color: rgb(248 215 218 / 67%);
  color: rgb(220 53 69 / 72%);
  text-align: center;

  .message {
    padding: 0.25rem;
    display: block;
  }

  .message:empty {
    display: none;
  }
}

.reset-msg {
  // background-color: rgb(248 215 218 / 67%);
  color: rgb(220 53 69 / 72%);
  text-align: center;

  .resetmessage {
    padding: 0.25rem;
    display: block;
    font-size: 1.5rem;
  }

  .message:empty {
    display: none;
  }
}