// Tabs
%tabItem {
  color: var(--color-text-dark) !important;
  background-color: hsl(0deg 0% 100% / 55%) !important;
  border: 1px solid #ccc !important;
  padding: var(--space-stretch-l) !important;
}
%tabItemSelected {
  color: var(--color-secondary) !important;
  background: hsl(202.55deg 59% 53.14% / 11%) !important;
  border-bottom: 0 !important;
}
%tabContainer {
  padding: 2.5rem 2rem;
  background: hsl(202.55deg 59% 53.14% / 11%);
  border: 1px solid hsl(202.55deg 82.12% 49.37% / 13%);
  border-top: 0;
}

%tabItemAlternate {
  color: hsl(0deg 0% 20% / 75%) !important;
  background-color: hsl(0deg 0% 100% / 30%) !important;
  padding: var(--space-stretch-l) !important;
}
%tabItemAlternateSelected {
  color: var(--color-text-primary) !important;
  background: var(--color-bg-light) !important;
  border-bottom: 0 !important;
}
%tabItemAlternateSelectedIndicator {
  background-color: var(--color-text-primary) !important;
}
%tabContainerAlternate {
  padding: 2.5rem 2rem;
  background: var(--color-bg-light);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

// Progress block

%ProgressBlock {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}
%ProgressBlockItem {
  background: var(--block-bg);
  border: var(--block-border);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  height: 100%;
}
%ProgressBlockItemTitle {
  font-weight: 600;
}

// table

%tableScrollbar {
  & ::-webkit-scrollbar {
    // background-color: #fff;
    width: 0.3rem;
  }

  & ::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: #eee;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 12px;
    // border: 4px solid #fff;
  }

  & ::-webkit-scrollbar-button {
    display: none;
  }
}

// title

%h2 {
  font-size: var(--font-size-heading-2);
  font-weight: 400;
}

// v Tab

%verticalTab {
  max-width: 16rem;
  width: 30%;
  border-right: 1px solid #ccc;
  position: relative;
}
%verticalTabList {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10%;
  height: 12%;
}

%verticalTabListLi {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: default;
  border-inline-end: 3px solid transparent;
}

%verticalTabListLiActive {
  background: var(--color-bg-light);
  border-color: var(--color-primary);
}

%verticalTabListLiActive_LightBg {
  background: #ededed;
  border-color: var(--color-primary);
}

%verticalTabListLiCount {
  margin-inline-start: auto;
  border-radius: 100%;
  padding: 0.25rem;
  background: rgb(255 255 255);
  flex-shrink: 0;
  border: 1px solid #e7e7e7;
  font-size: 0.75rem;
}

// status tab item icons
%available {
  color: #4199ce;
  font-size: 1rem;
}

%inProgress {
  color: #a3c1ad;
  font-size: 1rem;
}

%completed {
  color: #20d489;
  font-size: 1rem;
}

%ready {
  color: #009000;
  font-size: 1rem;
}

%locked {
  color: #c0c0c0;
  font-size: 1rem;
}
