.table-box1 {
  height: 22rem;
  margin-top: 0.6rem;
  overflow-y: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  // overflow: scroll;
  
}
table thead th {
  border: 1px solid #868686;
  background: #68acd5; // #4199ce;
  color: #fff;
  position: sticky;
  top: -1px;
  font-weight: 600;
  padding: 0.5rem;
}
// tbody{
//   overflow-y: auto;
//   height: 100px;
// }
table tbody td {
  padding: 0.5rem;
  border: 1px solid hsl(0deg 1.26% 53.14% / 40%);
  border-collapse: collapse;
  text-align: center;
  background: #fff;
  transition: all 0.2s ease-in-out;
}
table tbody tr:nth-child(even) td {
  background-color: #f5f5f5;
}
table tbody tr:hover td {
  background-color: hsl(200.73deg 94.83% 77.25% / 19%);
  transition: all 0.2s ease-in-out;
}

.pyyi_table-zerostate {
  margin: 2rem;
  font-size: 1.25rem;
  color: #cacaca;
  font-weight: 400;
  text-align: center;
}

.table-scroll::-webkit-scrollbar {
  width: 0.3rem;
}

.table-scroll::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #eee;
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 12px;
  // border: 4px solid #fff;
}

.table-scroll::-webkit-scrollbar-button {
  display: none;
}
