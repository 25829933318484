.progress-block {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
  }
  &-title {
    font-weight: 600;
    border-inline-start: 3px solid var(--color-primary);
    padding-inline-start: 0.25rem;
  }

  &-title-withSwitch {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-switch {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &-content {
    padding-top: 0.75rem;
    flex-grow: 1;
    max-height: 17.5rem;
    overflow-y: auto;
    // box-shadow: 0 7px 14px 5px rgb(65 69 88 / 10%) inset;
    // border: 1px solid black;
  }
  &-footer {
    font-size: 0.875rem;
    text-align: center;
  }
}

// Concenpt learned list
.chip-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  align-items: start;
}

.chip-items {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  padding-inline-start: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: hsl(37.67deg 87.45% 50% / 15%);
}

.item-excount {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  background-color: var(--color-primary);
}
