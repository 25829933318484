// .pyui_main-charts {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
// }

// .concepts-learned {
//   display: grid;
//   grid-template-columns: 1fr;
//   position: relative;
//   // min-width: 3rem;
//   // flex-direction: column;
// }

// // .concept-title {
// //   margin-bottom: 1rem;
// //   font-weight: 700;
// //   text-align: left;
// //   display: grid;
// //   grid-template-columns: repeat(2, 1fr);
// //   align-items: center;
// //   // justify-content: space-between;
// // }

// // .main-chip {
// //   padding: 0.5rem;
// //   // min-width: 18rem;
// //   height: 14.5rem;
// //   overflow-y: auto;
// //   // box-shadow: 0 7px 14px 5px rgb(65 69 88 / 10%) inset;
// //   // border: 1px solid black;
// // }

// .main-chip::-webkit-scrollbar {
//   // background-color: #fff;
//   width: 0.5rem;
// }

// .main-chip::-webkit-scrollbar-track {
//   border-radius: 16px;
//   background-color: #eee;
// }

// .main-chip::-webkit-scrollbar-thumb {
//   background-color: #babac0;
//   border-radius: 16px;
//   // border: 4px solid #fff;
// }

// .main-chip::-webkit-scrollbar-button {
//   display: none;
// }

// .sub-title {
//   text-align: center;
//   margin-top: 0.75rem;
//   font-weight: 600;
// }

// .concept-switch {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   //
// }

// .concept-switch .react-switch {
//   margin-left: 0.5rem;
// }
.layout__wrapper {
  // background-image: linear-gradient(0deg, hsl(215, 14%, 16%) 0%, hsl(215, 19%, 29%) 100%);
  border-radius: 3px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, .25);
  margin: 3rem auto;
  max-width: 350px;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  // margin: 0rem 2rem;
  // box-shadow: 0 12px 16px rgba(0, 0, 0, .25);
}

.header {
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.store-container {
  text-align: center;
  padding: 5px;
  background-color: rgb(0, 247, 255);
}

.items-container {
  text-align: center;
}

.item-container {
  padding: 5px;
  border-bottom: 1px solid grey
}

.column {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.column::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

//svg 
.svgshape_svgdescp {
  display: grid;
  grid-template-columns: 6rem auto;
  border-bottom: 1px solid grey // align-items: flex-start;

}

.dragdrop-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatbot-main {
  display: flex;
  align-items: center;
  justify-content: center;

}