:root {
  --color-neutral: #333333;
  --color-primary: #9c27b0;
  --color-accent: #f58634;
  --color-secondary: #3e4095;
  --color-text-default: var(--color-neutral);
  --color-bg-light: #fff;
  --body-bg: #f8f9fa;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.admin-app-section{
  /* margin-left: 5rem; */
  margin-left: 5rem;
  padding: 1rem;
}
