
.table-box-heading {
  padding: 0.25rem;
  display: flex;
  // grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}


// table,
// th,
tbody td {
  background: #fff;
  border: 1px solid hsla(0,1%,53%,.4);
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: center;
  transition: all .2s ease-in-out;

}

// tr {
//   width: 5rem;
// }

thead th {
  // padding: 0.5rem;
  border: 1px solid black;
  border-collapse: collapse;
}



table thead th {
  background: #68acd5;
  border: 1px solid #868686;
  color: #fff;
  font-weight: 600;
  padding: 0.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
    }
table tbody tr:hover td {
      background-color: hsl(200.73deg 94.83% 77.25% / 19%);
      transition: all 0.2s ease-in-out;
    }
table tbody td {
      background: #fff;
      border: 1px solid hsla(0,1%,53%,.4);
      border-collapse: collapse;
      padding: 0.5rem;
      text-align: center;
      transition: all .2s ease-in-out;
  }

    table tbody tr:nth-child(even) td {
      background-color: #f5f5f5;
  }
  table tbody tr:hover td {
    background-color: rgba(142,214,252,.19);
    transition: all .2s ease-in-out;
}