:root,
[data-theme="light"] {
  --font-size-xxxxl: 3rem;
  --font-size-xxxl: 2rem;
  --font-size-xxl: 1.5rem;
  --font-size-xl: 1.25rem;
  --font-size-l: 1.15rem;
  --font-size-m: 1rem;
  --font-size-base: 0.875rem;
  --font-size-s: 0.75rem;
  --font-size-xs: 0.65rem;
  --font-size-xxs: 0.55rem;
  --font-size-xxxs: 0.45rem;

  --color-text-default: var(--color-neutral);
  --color-text-light: var(--color-white);
  --color-text-dark: var(--color-black);
  --color-text-primary: var(--color-primary);
  --color-text-secondary: var(--color-secondary);
  --color-text-accent: var(--color-accent);

  --color-text-default-90: var(--color-neutral-op-90);
  --color-text-default-80: var(--color-neutral-op-80);
  --color-text-default-75: var(--color-neutral-op-75);

  --font-size-heading-5: 0.875rem;
  --font-size-heading-4: 1rem;
  --font-size-heading-3: 1.25rem;
  --font-size-heading-2: 1.5rem;
  --font-size-heading-1: 2rem;

  --color-heading-text-default: var(--color-neutral);
  --color-heading-text-default-inverse: var(--color-white);
  --color-heading-text-primary: var(--color-primary);
  --color-heading-text-secondary: var(--color-secondary);
  --color-heading-text-accent: var(--color-accent);

  // Icons
  --icon-size-xxxxl: 3rem;
  --icon-size-xxxl: 2rem;
  --icon-size-xxl: 1.5rem;
  --icon-size-xl: 1.25rem;
  --icon-size-l: 1.15rem;
  --icon-size-m: 1rem;
  --icon-size-base: 0.875rem;
  --icon-size-s: 0.75rem;
  --icon-size-xs: 0.65rem;
  --icon-size-xxs: 0.55rem;
  --icon-size-xxxs: 0.45rem;

  --color-icon-default: var(--color-neutral);
  --color-icon-default-inverse: var(--color-white);
  --color-icon-primary: var(--color-primary);
  --color-icon-secondary: var(--color-secondary);
  --color-icon-accent: var(--color-accent);

  // background
  --color-bg-light: var(--color-white);

  // spacing
  --space-xxxxxl: 4rem;
  --space-xxxxl: 3.5rem;
  --space-xxxl: 3rem;
  --space-xxl: 2.5rem;
  --space-xl: 2rem;
  --space-l: 1.5rem;
  --space-m: 1rem;
  --space-s: 0.5rem;
  --space-xs: 0.25rem;
  --space-xxs: 0.125rem;

  --space-stretch-l: 0.75rem 1.5rem;
  --space-stretch-m: 0.5rem 1rem;
  --space-stretch-s: 0.25rem 0.5rem;
  --space-stretch-xs: 0.125rem 0.25rem;
  --space-stretch-xxs: 0.125rem 0.06rem;
  --space-squish-xxl: 1.25rem 2.5rem;
  --space-squish-xl: 1rem 2rem;
  --space-squish-l: 0.75rem 1.5rem;
  --space-squish-m: 0.5rem 1rem;
  --space-squish-s: 0.25rem 0.5rem;
  --space-squish-xs: 0.125rem 0.25rem;
  --space-squish-xxs: 0.06rem 0.125rem;

  // border
  --border-default: 1px solid #ccc;

  // Shadow
  --box-shawdow-s: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  --box-shadow-m: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  //
  --block-border: 1px solid hsl(0deg 0% 83.61% / 68%);
  --block-bg: var(--color-white);
}
