:root {
  --color-linkbutton-fg: var(--color-secondary);
  --color-linkbutton-fg-hover: var(--color-secondary-op-80);
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  background-color: #ccc;
  color: #3e3d3d;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  border: 1px solid #ccc;
  margin: 0;

  &.small {
    padding: 0.25rem 0.75rem;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.fullWidth {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  &:hover {
    background-color: #bbb;
    transition: all 0.2s ease-in-out;
  }

  //primary button //orange
  &.primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: white;

    &:hover {
      background-color: var(--color-primary-op-80);
      //  transition: all 0.2s ease-in-out;
    }

    &.disabled {
      color: white;
    }
  }

  //secondary button // blue
  &.secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: white;

    &:hover {
      background-color: var(--color-secondary-op-80);
      //  transition: all 0.2s ease-in-out;
    }

    &.light {
      background-color: hsl(202.63deg 74.89% 43.73% / 17%);
      border-color: hsl(202.63deg 74.89% 43.73% / 37%);
      color: var(--color-secondary);

      &:hover {
        background-color: hsl(202.63deg 74.89% 43.73% / 37%);
      }
    }
  }
}

.link-button {
  padding: 0.25rem;
  color: var(--color-linkbutton-fg);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  text-decoration: underline;
  background: transparent;
  border: 0;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: var(--color-linkbutton-fg-hover);
    transition: all 0.2s ease-in-out;
  }

  &.small {
    font-size: 0.85rem; //0.75rem
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  //primary //orange
  &.primary {
    color: var(--color-primary);

    &:hover {
      background: hsl(0deg 0% 100% / 40%);
      border-radius: 6px;
    }

    &.disabled {
      color: #555;
    }
  }
}

.icon-button {
  padding: 0.5rem 0.5rem;
  color: #333;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  text-decoration: underline;
  background: #eeeeee;
  transition: all 0.4s ease-in-out;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-linkbutton-fg);

  >svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  &:hover {
    color: var(--color-linkbutton-fg-hover);
    transition: all 0.2s ease-in-out;
  }

  &.small {
    font-size: 0.75rem;

    >svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  //secondary button // blue
  &.secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: white;

    &:hover {
      background-color: var(--color-secondary-op-80);
      //  transition: all 0.2s ease-in-out;
    }
  }
}