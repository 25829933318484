:root {
  --color-input-br: rgba(145, 142, 142, 0.37);
  --color-input-br-hover: var(--color-secondary-op-80);
  --color-input-focus: #8ed6fc;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// * Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 0.875rem;
}
.input {
  border: 1px solid var(--color-input-br);
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  font-size: 0.875rem;
  color: var(--color-text-default);
  background: #fff;
  transition: all 0.2s ease-in;
  width: 100%;

  &:hover {
    border: 1px solid var(--color-input-br-hover);
  }

  &:focus {
    outline: 1px solid var(--color-input-focus);
    border-color: var(--color-input-focus);
    transition: all 0.2s ease-in;
  }

  &.error {
    border-color: rgb(215, 20, 20);

    &::placeholder {
      opacity: 1;
      color: rgb(215, 20, 20);
    }
  }
}

select {
  border: 1px solid var(--color-input-br);
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  font-size: 0.875rem;
  color: var(--color-text-default);
  background: #fff;
  transition: all 0.2s ease-in;
  text-overflow: ellipsis;
  &:hover {
    border: 1px solid var(--color-input-br-hover);
  }

  &:focus {
    outline: 1px solid var(--color-input-focus);
    border-color: var(--color-input-focus);
    transition: all 0.2s ease-in;
  }
  &.error {
    border-color: rgb(215, 20, 20);
  }
}

// search Filter

.pyui_search-filter {
  // border: 1px solid #ccc !important;
  border-radius: 2rem;
  box-shadow: 0 1px 4px 0 rgba(12, 12, 13, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.15);

  fieldset {
    border: none !important;
  }

  .pyui_search-filter-icon {
    color: var(--color-primary);
  }
}

:focus-within {
  .pyui_search-filter {
    outline: 1px solid var(--color-input-focus);
  }
}
